<template>
    <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
        <div class="container">
            <div class="az-content-left az-content-left-components">
                <div class="component-item">
                    <label>Decentralized Identifier</label>
                    <nav class="nav flex-column">
                        <router-link
                                :to="{ name: 'identities-home'}"
                                class="nav-link"
                                exact
                        >Home
                        </router-link>
                        <router-link
                                :to="{ name: 'identities-catalogs'}"
                                class="nav-link"
                        >Catalogs
                        </router-link>
                        <router-link
                                :to="{ name: 'claim-requests'}"
                                class="nav-link"
                        >Claim Requests
                            <span class="notification notification-red" v-if="outstandingClaims>0">{{outstandingClaims}}</span>
<!--                            <span-->
<!--                                class="outstanding"-->
<!--                                v-if="outstandingClaims>0"-->
<!--                        >{{outstandingClaims}}</span>-->
                        </router-link>
                        <router-link
                                :to="{ name: 'attestation-requests'}"
                                class="nav-link"
                        >Attestation Requests <span class="notification notification-red" v-if="outstandingAttestations>0">{{outstandingAttestations}}</span>
<!--                            <span-->
<!--                                class="outstanding"-->
<!--                                v-if="outstandingAttestations>0"-->
<!--                        >{{outstandingAttestations}}</span>-->
                        </router-link>
                    </nav>
                </div>
            </div>
            <div
                    class="az-content-body pd-lg-l-40 d-flex flex-column"
                    style="min-height: 600px;"
            >
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import EventBus from "@/event-bus";

    export default {
        name: "IdentityLayout",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                outstandingClaims: 0,
                outstandingAttestations: 0
            };
        },
        async mounted() {
            await this.getOutstanding();
        },
        methods: {
            async getOutstanding() {
                try {
                    let reply = await this.$identity.get(`/identity/dids/authorizations/outstanding`);
                    this.outstandingClaims = reply.data ? reply.data.outstanding_claims_count : 0;
                    this.outstandingAttestations = reply.data ? reply.data.outstanding_attestations_count : 0;
                    EventBus.$emit('outstandingClaims', this.outstandingClaims);
                    EventBus.$emit('outstandingAttestations', this.outstandingAttestations);
                } catch (e) {
                    this.$toastr.e("Error retrieving data", "Error");
                    console.log(e);
                }
            },
        }

    }
</script>

<style scoped>
    .outstanding {
        background-color: #dc3545;
        color: white;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        text-align: center;
        float: right;
        font-weight: 600;
        font-size: 9px;
        margin-right: 5px;
    }
</style>